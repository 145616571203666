import React, { useState,createContext } from "react"

// Create the context
export const VisualsStateContext = createContext();

// Create a provider component
export const VisualsStateProvider = ({ children }) => {
  const [visualsState, setVisualsState] = useState({
    height: '600px',
    // Add more global state variables here
  });

  return (
    <VisualsStateContext.Provider value={{ visualsState, setVisualsState }}>
      {children} 
    </VisualsStateContext.Provider>
  );
};

 