import { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { DashboardContext } from "states/DashboardContext";
import PeopleCount from "./components/PeopleCount";
import BuildingsTanzania2 from "./components/buildingsTanzania2";
import PeopleByRegion from "./components/people-vy-regions/PeopleByRegion";
import PopulationPyramid2 from "./components/populationPyramid/PopulationPyramid2";
import PopulationTrend from "./components/populationTrend";
import ResidentialAddresses from "./components/residentialAddress";
import SexRatio from "./components/sexRatio";
import SocialServices2 from "./components/socialServices2";
import { CensusMaterialTabs } from "ui/components/census/CensusMaterialTabs";
import DisabilityVisual from "./components/disability";

function CensusResults() {
  // import global state
  const { languageState } = useContext(DashboardContext);
  const [language] = languageState;

  //  return the census year to 2022

  // local state
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleIndexChange = (e, newIndex) => setSelectedIndex(newIndex);
  const tabsInfo = [
    {
      label: language ? "Population" : "Idadi ya watu",
      path: ".",
    },
    {
      label: language
        ? "Population Pyramid & Specific Groups"
        : "Piramidi ya Idadi ya watu",
      path: "population-and-groups",
    },
    {
      label: language ? "Buildings" : "Majengo",
      path: "buildings",
    },
    {
      label: language ? "Social Services" : "Huduma za jamii",
      path: "services",
    },
    {
      label: language ? "Disability" : "Ulemavu",
      path: "disability",
    },
  ];
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-stretch w-full min-h-screen gap-10 px-4 py-10">
      <CensusMaterialTabs
        value={selectedIndex}
        handler={handleIndexChange}
        options={tabsInfo}
        navFn={(path) => navigate(`/dissemination/results/${path}`)}
      />

      <Outlet />
    </div>
  );
}

export default CensusResults;

// section label
export const SectionLabel = ({ label, sub }) => {
  return (
    <p
      className={`font-bold capitalize ${
        sub ? "text-lg" : "text-xl"
      } whitespace-nowrap`}
    >
      {label}
    </p>
  );
};

export function CensusResultsPopulation() {
  return (
    <>
    <div className="flex justify-center"><p>Source: Population and Housing (PHC) 2022, National Bureau of Statistics, Tanzania</p></div>
      <PeopleCount />
      <PeopleByRegion year={2022} />
      <SexRatio />
      <PopulationTrend />
    </>
  );
}

export function CensusResultsPyramidAndGroups() {
  return (
    <>
    <div className="flex justify-center"><p>Source: Population and Housing (PHC) 2022, National Bureau of Statistics, Tanzania</p></div>
    <PopulationPyramid2 />
    </>
  );
}

export function CensusResultsBuildings() {
  return (
    <>
    <div className="flex justify-center"><p>Source: Population and Housing (PHC) 2022, National Bureau of Statistics, Tanzania</p></div>
      <BuildingsTanzania2 />
      <ResidentialAddresses />
    </>
  );
}

export function CensusResultsServices() {
  return (
    <>
      <div className="flex justify-center"><p>Source: Population and Housing (PHC) 2022, National Bureau of Statistics, Tanzania</p></div>
      <SocialServices2 />;
    </>
  )
}

export function CensusDisability() {
  return (
    <>
      <div className="flex justify-center"><p>Source: Population and Housing (PHC) 2022, National Bureau of Statistics, Tanzania</p></div>
      <DisabilityVisual />;
    </>
  )
}

