import React, { useState } from 'react';
import CensusLoadingCmp from 'ui/elements/census/CensusLoadingCmp';

export const SiteEmbed = ({ url }) => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIsIframeLoaded(true);
  };

  return (
    <main className="max-w-full max-h-full h-full ml-3 overflow-hidden" style={{border:'1px solid rgba(0,0,0,0.115)'}}>
      <div style={{display: isIframeLoaded? 'none':'block'}} class="h-full"> 
        <CensusLoadingCmp className="flex h-full w-full justify-center align-middle" /> 
      </div>
      <iframe 
        onLoad={handleIframeLoad}  
        style={{height:"100%", width:"100%", visibility: isIframeLoaded? 'visible':'hidden'}} 
        className="embed-site" 
        src={url}
        frameborder="0"
      ></iframe>
    </main>
  )
  
};

export default SiteEmbed;
