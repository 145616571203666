import React, { useEffect, useRef, useState, useContext } from 'react';
import { round } from 'lodash';
import { VisualsStateContext } from './VisualsStateContext';
 

const TableauEmbed = ({ url }) => {
  const ref = useRef(null);
  const vizRef = useRef(null);

  const {visualsState, setVisualsState} = useContext(VisualsStateContext);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://public.tableau.com/javascripts/api/tableau-2.8.0.min.js';
    script.async = true;
    
    script.onload = () => {
      if (window.tableau) initTableauViz(url);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      if (vizRef.current)  vizRef.current.dispose();
    };

  }, []);


  useEffect(() => {
    if (vizRef.current) vizRef.current.dispose();
    if (window.tableau) initTableauViz(url);
  }, [url]);

 
  const handleDimensions  = (initial = false) => {
    if (!vizRef.current)  return;

    const maxHeight = getTallestTab();
    let maxWidth = getWidestTab();
    const workbook = vizRef.current.getWorkbook();
    const activeSheet = workbook.getActiveSheet();
    const sheetSize = activeSheet.getSize();

    let height = sheetSize.maxSize.height;
 
    // for footer text //set tallest for now
    let footerHeight = 200;
    let newHeight = (maxHeight ?? height ) + footerHeight;
 
    let viewWidth = round(document.body.getBoundingClientRect().width);
    let newWidth = maxWidth < viewWidth ? maxWidth : viewWidth - 80;
    if(maxWidth > 1600) maxWidth = 1000;

    let iframe = document.querySelector('.embeded-dashboard-content');
    let currentHeight = iframe.getBoundingClientRect().height;

    if(!!newHeight) {
      setVisualsState({...visualsState, height:newHeight, width:newWidth, viewWidth, maxWidth});
    }
  }
 
  const getTallestTab = () => {
    if (!vizRef.current) return 0;

    const workbook = vizRef.current.getWorkbook();
    const sheets = workbook.getPublishedSheetsInfo();
    let maxHeight = 0;

    sheets.forEach(sheet => {
      try {
        const sheetSize = sheet.getSize();
        let height = sheetSize.maxSize.height
        if ( height > maxHeight) maxHeight = height;
      } catch (error) {
      }
    });
    return maxHeight;
  };

  const getWidestTab = () => {
    if (!vizRef.current) return 0;

    const workbook = vizRef.current.getWorkbook();
    const sheets = workbook.getPublishedSheetsInfo();
    let maxWidth = 0;

    sheets.forEach(sheet => {
      try {
        const sheetSize = sheet.getSize();
        let width = sheetSize.maxSize.width
        if ( width > maxWidth) maxWidth = width;
      } catch (error) {
      }
    });
    return maxWidth;
  };



  const initTableauViz = (vizUrl) => {
    const vizContainer = ref.current;
    if (!vizContainer) return;
    vizContainer.innerHTML = ''; // Clear the container

    vizRef.current = new window.tableau.Viz(vizContainer, vizUrl, {
      width:  '100%',
      height: '100%',
      hideTabs: false,
      hideToolbar: true,
      
      onFirstInteractive: () =>{ 
        // This function is called when the viz is fully loaded
        handleDimensions(true);
      },
      onTabSwitch: (event) => {
        handleDimensions();
      }
    });
  };

  return <div ref={ref}  style={{ width:'100%',  height: '100%' }}   />;
};

export default TableauEmbed;
