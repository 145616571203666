import request from "../axios";
import qs from "qs";
import { environment } from "../../environment";

export default function geoService(params) {
  params = qs.stringify(params);
  
  return request({
    url: "cite/ows?" + params,
    baseURL: environment.GEOSERVER_URL,
    method: "get",
    timeout: 72000,
    bfLoading: false,
    isAlertErrorMsg: false,
    headers: {
      "content-type": "application/json",
    },
  });
}
