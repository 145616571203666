import React from "react";
import { Typography } from "@mui/material";
import { BsSearch } from "react-icons/bs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Papa from "papaparse";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { DashboardContext } from "states/DashboardContext";
import DrillMap from "ui/elements/census/DrillMap";

function PeopleByRegion(props) {
  // global state
  const {
    languageState,
    censusDefaultMapDataState,
    censusMapIsDrillDownState,
    censusMapDrillDownAreaNameState,
    censusMapDrillDownDataState,
    censusMapDrillDownLevelState,
    censusIsDefaultDataState,
    censusYearSliderValueState,
    censusMapDataState,
    censusSubGroupNameState,
    censusIndicatorNameState,
  } = useContext(DashboardContext);
  const [language] = languageState;
  const [censusDefaultMapData] = censusDefaultMapDataState;
  const [censusMapIsDrillDown, setCensusMapIsDrillDown] =
    censusMapIsDrillDownState;
  const [censusMapDrillDownLevel, setCensusMapDrillDownLevel] =
    censusMapDrillDownLevelState;
  const [censusMapDrillDownAreaName, setCensusMapDrillDownAreaName] =
    censusMapDrillDownAreaNameState;
  const [censusMapDrillDownData, setCensusMapDrillDownData] =
    censusMapDrillDownDataState;
  const [censusIsDefaultData, setCensusIsDefaultData] =
    censusIsDefaultDataState;
  const [censusYearSliderValue, setCensusYearSliderValue] =
    censusYearSliderValueState;

  const [censusMapData, setCensusMapData] = censusMapDataState;

  const [censusIndicatorName, setCensusIndicatorName] =
    censusIndicatorNameState;
  const [censusSubGroupName, setCensusSubGroupName] = censusSubGroupNameState;
  //local
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // Get level data
  function getLevelData(level, area_code) {
    if (censusIsDefaultData === true) {
      let value = censusDefaultMapData.find(
        (e) =>
          parseInt(e.time_name.trim()) ===
            parseInt(censusYearSliderValue.trim()) &&
          e.area_level === level &&
          e.area_code === area_code
      );
      if (value) {
        return numberWithCommas(value.data_value);
      } else return "No Data";
    } else {
      if (censusMapData != []) {
        let value = censusMapData.find(
          (e) =>
            parseInt(e.time_name.trim()) ===
              parseInt(censusYearSliderValue.trim()) &&
            e.area_level === level &&
            e.area_code === area_code
        );
        if (value) {
          return numberWithCommas(value.data_value);
        } else return "No Data";
      }
    }
  }

  const handleDownload = () => {
    // Prepare the data to download
    let data = null;
    if (censusMapIsDrillDown === false) {
      data =
        censusIsDefaultData === true
          ? censusDefaultMapData.filter(
              (e) =>
                e.area_level === "LVL3" &&
                parseInt(e.time_name) === parseInt(censusYearSliderValue)
            )
          : censusMapData.filter(
              (e) =>
                e.area_level === "LVL3" &&
                parseInt(e.time_name) === parseInt(censusYearSliderValue)
            );
    } else {
      data =
        censusIsDefaultData === true
          ? censusDefaultMapData.filter(
              (e) =>
                parseInt(e.parent_code) === parseInt(censusMapDrillDownData) &&
                e.area_level === censusMapDrillDownLevel &&
                parseInt(e.time_name) === parseInt(censusYearSliderValue)
            )
          : censusMapData.filter(
              (e) =>
                parseInt(e.parent_code) === parseInt(censusMapDrillDownData) &&
                e.area_level === censusMapDrillDownLevel &&
                parseInt(e.time_name) === parseInt(censusYearSliderValue)
            );
    }

    // Rename the columns
    const renamedColumnData = data.map((item) => ({
      AreaName: item.area_name,
      DataValue: item.data_value,
    }));

    // Generate the CSV string from the data
    const csv = Papa.unparse(renamedColumnData);

    // // Trigger the download of the CSV file
    var fileDownload = require("js-file-download");
    censusIsDefaultData === true
      ? fileDownload(csv, `Popoulation Size.csv`)
      : fileDownload(csv, `${censusIndicatorName} - ${censusSubGroupName}.csv`);
  };

  const [searchText, setSearchText] = useState("");
  const handleSearchTextChange = (e) => setSearchText(e.target.value);
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    // ensure that default data is used
    setCensusIsDefaultData(true);
  }, []);

  useEffect(() => {
    // check if drill down
    if (censusMapIsDrillDown === true) {
      setFilteredRows((prev) => {
        prev = censusDefaultMapData.filter(
          (row, index) =>
            parseInt(row.time_name) === props.year &&
            row.area_level === censusMapDrillDownLevel &&
            parseInt(row.parent_code) === parseInt(censusMapDrillDownData)
        );
        return prev;
      });
    } else {
      setFilteredRows((prev) => {
        prev = censusDefaultMapData.filter(
          (row, index) =>
            parseInt(row.time_name) === props.year && row.area_level === "LVL3"
        );
        return prev;
      });
    }
  }, [censusDefaultMapData, censusMapIsDrillDown, censusMapDrillDownLevel]);

  useEffect(() => {
    // if drill down
    if (censusMapIsDrillDown === true) {
      if (searchText === "") {
        setFilteredRows((prev) => {
          prev = censusDefaultMapData.filter(
            (row, index) =>
              parseInt(row.time_name) === props.year &&
              row.area_level === censusMapDrillDownLevel &&
              parseInt(row.parent_code) === parseInt(censusMapDrillDownData)
          );
          return prev;
        });
      } else {
        setFilteredRows((prev) => {
          prev = censusDefaultMapData.filter(
            (row) =>
              row.area_name
                .toLowerCase()
                .split(" ")
                .join("")
                .includes(searchText.toLowerCase().split(" ").join("")) &&
              parseInt(row.time_name) === props.year &&
              row.area_level === censusMapDrillDownLevel &&
              parseInt(row.parent_code) === parseInt(censusMapDrillDownData)
          );
          return prev;
        });
      }
    }
    // if not drill
    else {
      if (searchText === "") {
        setFilteredRows((prev) => {
          prev = censusDefaultMapData.filter(
            (row, index) =>
              parseInt(row.time_name) === props.year &&
              row.area_level === "LVL3"
          );
          return prev;
        });
      } else {
        setFilteredRows((prev) => {
          prev = censusDefaultMapData.filter(
            (row) =>
              row.area_name
                .toLowerCase()
                .split(" ")
                .join("")
                .includes(searchText.toLowerCase().split(" ").join("")) &&
              parseInt(row.time_name) === props.year &&
              row.area_level === "LVL3"
          );
          return prev;
        });
      }
    }
  }, [
    searchText,
    censusDefaultMapData,
    censusMapIsDrillDown,
    censusMapDrillDownLevel,
  ]);

  return (
    <div className="flex flex-col w-full bg-white shadow-xl rounded-xl overflow-clip lg:flex-row">
      <div className="bg-[white] w-full lg:w-[50%] h-full flex flex-col gap-3 px-2 py-4"> 
        <DrillMap year={props.year} mapHeight={"600"} />
      </div>
      <div className="bg-[white] w-full lg:w-[50%] h-[90vh] flex flex-col gap-3 px-2 py-4">
        <div className="px-3 w-full flex items-center justify-between gap-2 text-sm tracking-wide border border-[#C5C5C5] bg-[#F7F7F7] rounded-lg outline-none">
          <BsSearch className="text-lg text-gray-500" />
          <input
            className="w-full h-full py-2 bg-transparent outline-none "
            placeholder={
              language === true ? "Search by name" : "Tafuta  kwa jina"
            }
            value={searchText}
            onChange={handleSearchTextChange}
          />
        </div>
        <div className="flex flex-row justify-end w-full">
          <div className="flex  flex-row w-full gap-2">
            <Typography fontSize={"small"} sx={{ fontWeight: "bold" }}>
              {censusMapIsDrillDown === false
                ? "Tanzania"
                : censusMapDrillDownAreaName[0]}
            </Typography>
            <Typography fontSize={"small"}>
              {censusMapIsDrillDown === false
                ? getLevelData("LVL1", "TZ")
                : numberWithCommas(censusMapDrillDownAreaName[1])}
            </Typography>
          </div>
          <div className="flex justify-end">
            <Button
              onClick={handleDownload}
              variant="contained"
              startIcon={<DownloadIcon />}
              fullWidth={true}
              sx={{ height: 3 / 4, background: "#525252" }}
            >
              <p className="text-xs">
                {language === true ? "Download" : "Pakua"}
              </p>
            </Button>
          </div>
        </div>

        <TableContainer component={Paper} elevation={0}>
          <Table
            sx={{ width: "100%" }}
            size="medium"
            aria-label="a dense table"
          >
            <TableHead sx={{ position: "sticky", top: 0 }}>
              <TableRow sx={{ backgroundColor: "#0F7995" }}>
                <TableHeaderCell header={language === true ? "No." : "Na."} />
                <TableHeaderCell
                  header={
                    language === true
                      ? censusMapIsDrillDown !== true
                        ? "Regions"
                        : censusMapDrillDownLevel === "LVL5"
                        ? "Councils"
                        : censusMapDrillDownLevel === "LVL6"
                        ? "Contituencies"
                        : "Wards / Shehia"
                      : censusMapIsDrillDown !== true
                      ? "Mikoa"
                      : censusMapDrillDownLevel === "LVL5"
                      ? "Halmashauri"
                      : censusMapDrillDownLevel === "LVL6"
                      ? "Majimbo"
                      : "Kata / Shehia"
                  }
                />
                <TableHeaderCell
                  header={
                    language === true ? "Population size" : "Idadi ya watu"
                  }
                />
                <TableHeaderCell header="" />
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflowY: "scroll" }}>
              {filteredRows
                .sort((a, b) => b.data_value - a.data_value)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      fontSize: "large",
                    }}
                    // onClick={() => handleRowClick(row)}
                  >
                    <TableContentCell content={index + 1} />
                    <TableContentCell bold content={row.area_name} />
                    <TableContentCell
                      content={numberWithCommas(row.data_value)}
                    />
                    {/* <TableContentCell content="" /> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default PeopleByRegion;

const TableHeaderCell = ({ header }) => (
  <TableCell
    sx={{
      fontWeight: "bold",
      fontSize: "large",
      fontFamily: '"Poppins", sans-serif',
      color: "white",
    }}
  >
    {header}
  </TableCell>
);

const TableContentCell = ({ content, bold }) => (
  <TableCell
    sx={{
      fontWeight: bold ? "bold" : "normal",
      fontFamily: '"Poppins", sans-serif',
    }}
  >
    {content}
  </TableCell>
);
